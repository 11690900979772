import { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IInfoTooltipLink {
  tooltipTitle: any;
  tooltipData: any;
  placement?: any;
  className?: any;
  to?: any;
  hideTooltip?: any;
  disableProp?: any;
  state?: any;
  target?: any;
  tooltipClassName?: any;
  clickhandler?:any
}

/**
 * Info Tooltip Link Common Component
 * @date 11/20/2023 - 04:57:17 PM
 *
 * @param {{ placement: any; tooltipData: any; className: any; tooltipTitle: any; to: any; state:any; target:any;; tooltipClassName:any; }} {
  placement,
  tooltipData,
  className,
  tooltipTitle,
  to,
  state,
  target,
  tooltipClassName
}
 * @returns {*}
 */

const InfoTooltipLink: FC<IInfoTooltipLink> = ({
  placement,
  tooltipData,
  className,
  tooltipTitle,
  to,
  hideTooltip,
  disableProp,
  state,
  target,
  tooltipClassName,
  clickhandler
}) => {
  const renderTooltip = (props: any): any => {
    if (!hideTooltip) {
      return (<Tooltip className={tooltipClassName}>{props}</Tooltip>);
    }
    return (<></>)
  };
  return (
    <OverlayTrigger
      placement={placement}
      overlay={renderTooltip(tooltipData)}
      trigger={["hover", "hover"]}
    >
      <Link
        to={to}
        className={`${className} ${disableProp ? 'pe-none opacity-50' : ''}`}
        state={state}
        target={target}
        onClick={clickhandler}
      >
        {tooltipTitle}
      </Link>
    </OverlayTrigger>
  )
};

export default InfoTooltipLink;

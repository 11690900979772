import React, { FC, useState } from 'react';
import { Card, Nav, Tab } from "react-bootstrap";
import './index.scss';
import NotesTab from './notesTab';
import QuestionnairesTab from './questionnairesTab';
import { useDispatch, useSelector } from 'react-redux'
import { MdCircle } from 'react-icons/md';
import { ThunkDispatch } from '@reduxjs/toolkit'
import { companyCaseActions } from '../../../../redux/companyCasesModule/caseSlice';

interface ICaseTabs {
    caseID?:any;
    caseData?: any;
}
const CaseTabs: FC<ICaseTabs> = ({
    caseID,
    caseData,
}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const activeTabForCases = useSelector((state: any) => state.companyCaseSlice.activeTabForCases);
    const [activeTab, setActiveTab] = useState<string | null>(activeTabForCases);

    const handleDropdownSelect = (eventKey: string | null) => {
        for (const tab of caseTabsData) {
            if (eventKey === tab?.id) {
                setActiveTab(eventKey)
                dispatch(companyCaseActions?.changeCompanyCasesActiveTab({ activeTabForCases: eventKey }));
            }
        }
    };

    //Case Tabs Data
    const caseTabsData = [
        {
            id: 'questionnaires',
            name: 'Questionnaires',
            Component: <QuestionnairesTab />,
            disabled: false,
        },
        {
            id: 'documents',
            name: 'Documents',
            Component: 'Documents soon...',
            disabled: true,
        },
        {
            id: 'notes',
            name: 'Notes',
            Component: <NotesTab />,
            disabled: false,
        },
    ];

    const renderTabName = (name: string) => (
        <>
            {/* <span className='text-custom-danger me-2 pulse-circle position-relative d-inline-flex align-items-center'>
                <MdCircle size={8} />
            </span> */}
            {name}
        </>
    );

    return (
        <Card className="theme-case-tabs-cover flex-grow-1 border-0 pt-2">
            <Tab.Container
                id="theme-client-tabs"
                activeKey={activeTab as string}
                onSelect={handleDropdownSelect}
                unmountOnExit={true}
            >
                <Nav
                    className="theme-nav-bordered"
                >
                    {caseTabsData.map((tabNames) => {
                        const { id, name, disabled } = tabNames;
                        return (
                            <React.Fragment
                                key={id}
                            >
                                <Nav.Link
                                    eventKey={id}
                                    disabled={disabled}
                                    className={`position-relative text-primary bg-custom-primary-subtle me-1 text-center ${disabled ? 'opacity-50' : ''}`}
                                >
                                    {renderTabName(name)}
                                </Nav.Link>
                            </React.Fragment>
                        )
                    })}
                </Nav>

                <Tab.Content>
                    {caseTabsData.map((tabNames) => {
                        const { id, Component } = tabNames;
                        return (
                            <React.Fragment
                                key={id}
                            >
                                <Tab.Pane
                                    eventKey={id}
                                >
                                    {Component}
                                </Tab.Pane>
                            </React.Fragment>
                        )
                    })}
                </Tab.Content>
            </Tab.Container>
        </Card>
    )
}
export default CaseTabs;
import React, { FC } from "react";
import { Button, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface IRenderTableAction {
  controlId?: string;
  rowData?: any;
  customButtons?: any;
  wrapperClassName?: any;
}

/**
 * Render Action Functions For Data Grid
 *
 * @param {{ controlId: any; rowData: any; customButtons: any; }} param0
 * @param {*} param0.controlId
 * @param {*} param0.rowData
 * @param {*} param0.customButtons
 * @returns {*}
 */

const RenderTableAction: FC<IRenderTableAction> = ({
  controlId,
  rowData,
  customButtons,
  wrapperClassName
}) => {
  const renderTooltip = (props: any) => <Tooltip id={props}>{props}</Tooltip>;

  return (
    <Stack
      direction="horizontal"
      className={wrapperClassName}
      gap={1}
    >
      {customButtons?.map((buttonItem: any) => {
        return <React.Fragment key={buttonItem?.name}>
          {buttonItem?.enabled && <OverlayTrigger placement="top" overlay={renderTooltip(buttonItem?.title)} trigger={["hover", "hover"]}>
            {buttonItem?.type === 'link' ? <Link
              className={`custom-width-26 custom-height-26 d-inline-flex align-items-center justify-content-center p-1 lh-1 fs-5 theme-${buttonItem?.class}-btn text-custom-brown ${buttonItem?.disabled ? 'pe-none opacity-50' : ''}`}
              to={buttonItem?.customLink ?? `/app/${controlId}/${buttonItem?.name}/${rowData?.id}`}
              aria-label={buttonItem?.title}
              target={buttonItem?.target || "_self"}  
            >
              {buttonItem?.icon}
            </Link>
              : <Button
                className={`custom-width-26 custom-height-26 d-inline-flex align-items-center justify-content-center p-1 lh-1 fs-5 theme-${buttonItem.class}-btn text-custom-brown`}
                variant="link"
                disabled={buttonItem?.disabled}
                onClick={buttonItem?.handler}
                aria-label={buttonItem?.title}
              >
                {buttonItem.icon}
              </Button>
            }
          </OverlayTrigger>}
        </React.Fragment>
      })}
    </Stack>
  );
};

export default RenderTableAction;
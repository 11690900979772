import { Route, Routes } from 'react-router-dom';
import { baseRoutes } from '../../../routes/base-routes';
import CasesList from './index';
import { VIEW } from '../../../constants/global';
import ViewQuestionnaires from './tabs/questionnairesTab/view/View';


export const Cases = () => {
    return (
        <Routes>
            {<Route path="/" element={<CasesList />} />}
            {<Route path={`/${VIEW}/:caseId/:questionnaireid`} element={<ViewQuestionnaires />} />}
        </Routes>
    )
};
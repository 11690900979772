const EndPoint = {
    SET_ACCOUNT: '/auth/setup-account',
    RESET_PASSWORD: '/auth/reset-password',
    LOGIN_API: '/auth/login',
    OTP_API: '/auth/verify-login-code',
    REGENERATE_OTP_API: '/auth/regenerate-login-code',
    FORGOT_PASSWORD: '/auth/forgot-password',
    QUESTIONAIRRE_LIST: `/cases/questionnaire-list`,
    CLIENT_CASES: `/cases/list`,
    CLIENT_APPLICANT_LIST: `/cases/applicant-list`,
    VIEW_QUESTIONNNAIRE: "cases/questionnaire-view",
    SUBMIT_QUESTIONNAIRE: "/cases/questionnaire-submit",
    NOTAPPLICABLE_QUESTIONNAIRE: "/cases/mark-questionnaire-section-notapplicable",
    DOCUMENT_LIST: `/cases/get-document-list`,
    DELETE_DOCUMENT_LIST: `/cases/delete-case-document`,
    UPLOAD_DOCUMENT_LIST: `/cases/upload-request-file-in-documents`,
    VIEW_CASE_DOCUMENT: "/cases/document",
    DOCUMENT_VIEW_DATA: '/cases/document-view',
    DOCUMENT_VIEW_NEW_DATA: '/cases/get-case-form-view-data',
    UPLOAD_DOCUMENT: "cases/upload-request-file-in-documents",
    DOWNLOAD_DOCUMENT_NEW: "/cases/document-download-post",
    DOWNLOAD_DOCUMENT: "/cases/document-download",
    EDIT_UPLOAD_DOCUMENT: "/cases/upload-request-file-in-documents-edit",
    KB_DOC_TYPE_LIST_API: "/common-apis/list",
    NOTES_LIST: `/notes/list`,
    NOTES_ADD: `/notes/add`,
    NOTES_EDIT: `/notes/update`,
    NOTES_VIEW: `/notes/detail`,
    NOTES_DELETE: `/notes/delete`,
    CHANGE_PASSWORD: "/auth/change-password",
    LOGOUT_API: "/auth/logout",
    DASHBOARD_API: "/dashboard/dashboard-combine-api",
    
    UPLOAD_DOCUMENT_V2: "cases-v2/upload-request-file-in-documents",
    EDIT_UPLOAD_DOCUMENT_V2: "/cases-v2/upload-request-file-in-documents-edit",
    
    COMPANY_CASES_DROPDOWN_API: "/documents-and-questionnaire/list",
    COMPANY_CASES_DETAILS_API: "/documents-and-questionnaire/get-case-detail",
    COMPANY_QUESTIONAIRRE_LIST: `/case-questionnaire/questionnaire-list`,
    COMPANY_VIEW_QUESTIONNNAIRE: "/case-questionnaire/questionnaire-view",
    COMPANY_SUBMIT_QUESTIONNAIRE: "/case-questionnaire/questionnaire-submit",
    COMPANY_NOTAPPLICABLE_QUESTIONNAIRE: "/case-questionnaire/mark-questionnaire-section-notapplicable",
    COMPANY_DASHBOARD_API: "/dashboard-billing/dashboard-combined",
    COMPANY_EXPORT_LIST_NOTES: "/notes/notes-export",
}

export default EndPoint;
import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./datagrid.scss";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { REACT_TABLE_ORDER } from "../../../constants/global";

export interface IDataGridCheckboxCommon {
  columns: any;
  data: any;
  onPageChange?: any;
  onSort?: any;
  currentPage?: any;
  tableRecords?: any;
  HideTablePaginator?: Boolean;
  reorderableRows?: any;
  selectedProducts?: any;
  onSelectionChange?: any;
  onRowReorder?: any;
  selectionMode?: any;
  globalFilterFields?: any;
  filterDisplay?: any;
  emptyMessage?: any;
  globalFilterMatchMode?: any;
  isRowSelectable?:any;
  lazyRequired?: String;
}
interface ColumnInfo {
  field: string;
  order: number;
}

/**
 * Custom component for Data Grid Chekbox used at all places
 * @date 10/13/2023 - 6:18:55 PM
 *
 * @param {{ columns: any; data: any; onSort: any; onPageChange: any; tableRecords: any; HideTablePaginator: any; reorderableRows: any; selectedProducts: any; onSelectionChange: any; onRowReorder: any; selectionMode: any; }} {
  columns,
  data,
  onSort,
  onPageChange,
  tableRecords,
  HideTablePaginator,
  reorderableRows,
  selectedProducts,
  onSelectionChange,
  onRowReorder,
  selectionMode,
  globalFilterFields,
  filterDisplay,
  lazyRequired,
}
 * @returns
 */

const DataGridCheckboxCommon: React.FC<IDataGridCheckboxCommon> = ({
  columns,
  data,
  onSort,
  onPageChange,
  tableRecords,
  HideTablePaginator,
  reorderableRows,
  selectedProducts,
  onSelectionChange,
  onRowReorder,
  selectionMode,
  globalFilterFields,
  filterDisplay,
  emptyMessage,
  globalFilterMatchMode,
  isRowSelectable,
  lazyRequired,
}) => {
  const [sortOrder, setSortOrder] = useState(REACT_TABLE_ORDER);
  const [sortField, setSortField] = useState("");
  const [first, setFirst] = useState(0);
  const [multiSortMeta, setMultiSortMeta] = useState<[]>([]);

  const handlePageChange = (newPage: number, rows: any) => {
    onPageChange(newPage, rows); // Call the prop function to update currentPage in the parent component
  };
  //Sort List using header
  const handleSort = (event: any) => {
    setMultiSortMeta(event.multiSortMeta);
    setFirst(0);
    event.multiSortMeta.forEach((columnInfo: ColumnInfo) => {
      const { field, order } = columnInfo;
      const sortOrder = order !== 1 ? "DESC" : "ASC";
      setSortOrder(sortOrder);
      setSortField(field);
      // Call onSort with the updated sortOrder and sortField values
      onSort(sortOrder, field);
    });
  };
  

  return (
    <div className="d-flex dataGridMain flex-column h-100 overflow-auto w-100">
      <DataTable
        value={data}
        stripedRows
        onSort={handleSort}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
        sortIcon={
          sortOrder === "DESC" ? (
            <FiArrowDown className="p-sortable-column-icon" size={17} />
          ) : (
            <FiArrowUp className="p-sortable-column-icon" size={17} />
          )
        }
        className="flex-grow-1 h-auto overflow-auto"
        scrollable={true}
        scrollHeight="flex"
        reorderableRows={reorderableRows}
        onRowReorder={onRowReorder}
        selection={selectedProducts!}
        onSelectionChange={onSelectionChange}
        selectionMode={selectionMode}
        // dataKey="id"
        globalFilterFields={globalFilterFields}
        filterDisplay={filterDisplay}
        emptyMessage={emptyMessage}
        globalFilterMatchMode={globalFilterMatchMode}
        isDataSelectable={isRowSelectable} 
        lazy={lazyRequired === "No" ? false : true}
      >
        {columns.map((col: any) => (
          <Column
            key={col.field}
            sortable={col.sortable}
            field={col.field}
            header={col.header}
            headerClassName={`fs-15 ${col.headerClassName}`}
            style={{ width: col.width, minWidth: col.width }}
            body={col.body}
            bodyClassName={col.bodyClassName}
            rowReorder={col.rowReorder}
            selectionMode={col.selectionMode}
            filter={col.filter}
            filterMatchModeOptions={[{label: "Contains", value: "contains"}]}
            // filterMatchMode={'contains'}
            // filterMatchModeOptions={[{'Contains': "contains"}]}
            showFilterMatchModes={false}
            filterMatchMode={col.filterMatchMode}
            filterFunction={col.filterFunction}
          />
        ))}
      </DataTable>

      {/* Custom Pagination */}
      {/* {HideTablePaginator ? (
        ""
      ) : (
        <CustomPaginator
          data={data}
          onPageChange={handlePageChange}
          tableRecords={tableRecords}
        />
      )} */}
    </div>
  );
};

export default DataGridCheckboxCommon;

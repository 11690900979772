import { ThunkDispatch } from '@reduxjs/toolkit';
import { FC } from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DataGridCommon from '../../../../components/common/dataGrid/DataGridCommon';
import InfoTooltipLink from '../../../../components/common/InfoTooltipLink';
import { HIDE_TABLE_PAGINATOR } from '../../../../constants/global';
import appConfig from '../../../../helpers/config';
import { companyCaseActions } from '../../../../redux/companyCasesModule/caseSlice';
import { SvgIcons } from '../../../../components/common/SvgIcons';
import { MdLibraryBooks } from 'react-icons/md';
interface ICaseDetails {
  dashboardData?: any
}

const CaseDetails: FC<ICaseDetails> = ({
  dashboardData
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const sNoCellTemplate = (rowData: any, index: any) => {
    return <span className="min-w-20 d-inline-block text-center">{index?.rowIndex + 1}</span>
  }

  const employeeName = (rowData: any, index: any) => {
    return <span className="min-w-20 d-inline-block text-center text-capitalize">{rowData?.case_client_info?.client_full_name}</span>
  }

  const billingDetailsBody = (rowData: any, index: any) => {
    return <span className="min-w-20 d-inline-block text-center text-capitalize"></span>
  }

    const questionnaireDocumentBody = (status: any, count: any) => {
      switch (status) {
        case "Completed":
          return "Completed";
        case "No Data":
          return "";
        case "Pending":
          return (
            <Stack direction="horizontal" gap={2} className="justify-content-center">
              <span className="text-capitalize text-danger">
                {status}
              </span>
              <span className="lh-1 m-0 fw-semibold alert alert-danger rounded-circle px-1 py-0 border-0 w-20 h-20 d-inline-flex align-items-center justify-content-center ">
                {count}
              </span>
            </Stack>
          );
        default:
          return "";
      }
    };


    const overdueClassName = (status: string) => {
      switch (status) {
        case "N/A - Current":
          return "bg-success-subtle text-success";
        case "Does Not Apply":
          return "bg-dark-subtle text-dark";
        default:
          return "bg-danger-subtle text-danger";
      }
    };

    const billingTemplate = (rowData: any) => {
      const status = rowData?.billingDetail?.overByDays;

      const getLabel = (status: string) => {
        switch (status) {
          case "N/A - Current":
            return "Current";
          case "Does Not Apply":
            return "Does Not Apply";
          default:
            return "Overdue";
        }
      };

      return (
        <Badge pill className={`fs-14 lh-sm ${overdueClassName(status)}`}>
          {getLabel(status)}
        </Badge>
      );
    };
    

    const caseFileNoTooltipTemplate = (rowData: any) => {       
        return (
          <InfoTooltipLink
            placement="right-end"
            className="align-middle d-inline-block text-truncate mw-100"
            tooltipTitle={rowData.file_number}
            tooltipData={rowData.file_number}
            to={`${appConfig.basePath}/cases?caseID=${rowData?.id}`}
            // clickhandler={() => dispatch(companyCaseActions.changeCompanyCasesID({caseID : rowData?.id})) }
            target="_blank"
          />
        );
      };

    // Table Columns
    const columns = [
        {
            field: "s_no",
            header: "No.",
            body: sNoCellTemplate,
            headerClassName: "text-wrap",
            width: '50px',
        },
        {
            field: "file_number",
            header: "Case No.",
            body: caseFileNoTooltipTemplate,
        },
        {
            field: "case_client_info",
            header: "Employee Name",
            body: employeeName
        },
        {
            field: "case_type_info.name",
            header: "Case Type",
            headerClassName: "text-center",
            bodyClassName: "text-center",
        },
        {
            field: "billingDetail",
            header: "Billing",
            body: billingTemplate,
            headerClassName: "text-center",
            bodyClassName: "text-center",
        },
        {
            field: "case_status",
            header: "Status",
            headerClassName: "text-center",
            bodyClassName: "text-center",
        },
        {
            // field: "billingDetail",
            header: "Documents",
            // body: (rowData: any) => questionnaireDocumentBody(rowData?.findStatusOfDocuments?.status, rowData?.findStatusOfDocuments?.count), 
            headerClassName: "text-center",
            bodyClassName: "text-center",
        },
        {
            field: "questionnaires",
            header: "Questionnaires",
            body: (rowData: any) => questionnaireDocumentBody(rowData?.findStatusOfQuestionnaire?.status, rowData?.findStatusOfQuestionnaire?.count), 
            headerClassName: "text-center",
            bodyClassName: "text-center",
        },
    ];

  return (
    <>
      <div className="theme-card-header my-4">
        <Stack
          direction="horizontal"
          gap={3}
          className="flex-wrap"
        >
          <h5 className="mb-0 position-relative fw-bold d-flex align-items-center ps-0 gap-3 text-custom-secondary">
            <div className="align-items-center bg-primary d-inline-flex justify-content-center rounded text-white theme-icon-box custom-width-44 custom-height-44 rounded-1">
              <span className="theme-icon-box-inner text-center">
                <MdLibraryBooks size={28} />
              </span>
            </div>
            Case Details
          </h5>
        </Stack>
      </div>
      <div className="theme-accordion-data">
        <DataGridCommon
          columns={columns}
          data={dashboardData}
          HideTablePaginator={HIDE_TABLE_PAGINATOR}
        />
      </div>
    </>
  )
}

export default CaseDetails
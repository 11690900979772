import React, { FC } from "react";
import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import { MdPrint } from "react-icons/md";
import { useSelector } from "react-redux";
interface IExportPDFModal {
  show?: boolean;
  handleClose?: any;
  notesExportData?: any;
}

/**
 * Export PDF Modal Component
 *
 * @param {{ rowData: any; show: any; handleClose: any; notesExportData:any; }} {
  show,
  handleClose,
  notesExportData
}
 * @returns {*}
 */

const ExportPDFModal: FC<IExportPDFModal> = ({
  show,
  handleClose,
  notesExportData,
}) => {
  const companyDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);
  const viewData = notesExportData ?? [];

  const printNotes = () => {
    window.print();
  };

  const showEmployeeName = () => {
    const name = [companyDetailsData?.case_client_info?.first_name, companyDetailsData?.case_client_info?.middle_name, companyDetailsData?.case_client_info?.last_name].filter(Boolean).join(" ")
    const customLabel = `${name} ${companyDetailsData?.case_type_info?.name} [${companyDetailsData?.file_number}]`

    return customLabel
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered={true}
      scrollable={true}
      size="lg"
      className="theme-modal is-print-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title as="div" className="flex-fill pe-3">
          <Stack direction="horizontal" gap={2}>
            <h6 className="fw-semibold mb-0 me-auto">
              Notes for 
              <span className="text-capitalize">
                {" "}
                {showEmployeeName()}
              </span>
            </h6>
            <Button
              variant="primary"
              size="sm"
              className="min-w-80"
              onClick={(e) => printNotes()}
            >
              <MdPrint size={19} className="me-2" />
              Print
            </Button>
          </Stack>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body id="PrintNotes">
        {viewData &&
          viewData.map((printData: any) => (
            <div
              className="mx-n20 px-20 theme-border-bottom-gap-3"
              key={printData.id}
            >
              <Row className="g-2">
                {printData.items.map((item: any) => (
                  <Col key={item.title} className={item.className}>
                    <div className="small fw-semibold">
                      {item?.title || "N/A"}
                    </div>
                    <div
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html: item?.detail || "N/A",
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
      </Modal.Body>
    </Modal>
  );
};

export default ExportPDFModal;

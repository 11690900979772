import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './App.css';
import "./assets/css/style.scss";
import Loader from "./components/common/Loader";
import AppRoutes from './routes';

function App() {
  return (
    <>
      <Suspense fallback={<Loader isLoading={true} />}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </Suspense>
      <ToastContainer role="alert" className="text-capitalize-first"/>
    </>
  );
}

export default App;

import { combineReducers } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import authReducer from '../auth';
import questionairreSlice from '../../../redux/questionairreModule/questionairreSlice';
import documentSlice from '../../../redux/documentModule/documentSlice';
import notesSlice from '../../../redux/notesModule/notesSlice';
import dashboardSlice from '../../../redux/dashboardModule/dashboardSlice';
import companyCaseSlice from '../../../redux/companyCasesModule/caseSlice';
import companyNotesSlice from '../../../redux/companyNotesModule/notesSlice';
import companyQuestionnaireSlice from '../../../redux/companyQuestionnaireModule/questionnaireSlice';
import companyDashboardSlice from '../../../redux/companyDashboardModule/dashboardSlice';

const MainReducer = combineReducers(
  {
    auth: authReducer,
    questionairreSlice:questionairreSlice,
    documentSlice:documentSlice,
    notesSlice: notesSlice,
    dashboardSlice:dashboardSlice,
    companyCaseSlice:companyCaseSlice,
    companyNotesSlice:companyNotesSlice,
    companyQuestionnaireSlice:companyQuestionnaireSlice,
    companyDashboardSlice:companyDashboardSlice,
  }
);

const RootReducer = (state: any, action: any) => {
  if (action.type === "authentication/logout") {
    state = undefined
  }
  if (action.type === PURGE) {
    state = undefined
  }
  return MainReducer(state, action);
}
export default RootReducer;

import { ThunkDispatch } from '@reduxjs/toolkit';
import { FC, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { questionairreActions } from '../../../../redux/questionairreModule/questionairreSlice';

interface IQuestionnaires {
    dashboardData?: any
}

const Questionnaires: FC<IQuestionnaires> = ({
    dashboardData
}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        setData([{
            id: 1,
            count: dashboardData?.findCaseQuestionnaireStatus?.Completed > 0 ? dashboardData?.findCaseQuestionnaireStatus?.Completed : 0,
            title: dashboardData?.findCaseQuestionnaireStatus?.Completed > 1 ? "Questionnaires" : "Questionnaire"  ,
            status: "Completed",
            className: "dashboard-text-green",
        },
        {
            id: 2,
            count: dashboardData?.findCaseQuestionnaireStatus?.["In Progress"] > 0 ? dashboardData?.findCaseQuestionnaireStatus?.["In Progress"] : 0 ,
            title: dashboardData?.findCaseQuestionnaireStatus?.["In Progress"] > 1 ? "Questionnaires" : "Questionnaire"  ,
            status: "In Progress",
            className: "dashboard-text-orange",
        },
        {
            id: 3,
            count: dashboardData?.findCaseQuestionnaireStatus?.Pending > 0 ? dashboardData?.findCaseQuestionnaireStatus?.Pending : 0,
            title: dashboardData?.findCaseQuestionnaireStatus?.Pending > 1 ? "Questionnaires" : "Questionnaire"  ,
            status: "Pending",
            className: "dashboard-text-gray",
        }] )
    }, [dashboardData])
    
    return (
        <Card className="shadow-lg bg-white border-0 h-100">
            <Card.Header className="d-flex align-items-center flex-wrap bg-white p-3 border-0 gap-2">
                <Card.Title className="fw-bold mb-0 me-auto text-primary fs-20">Company Questionnaires</Card.Title>
                <Link
                    
                    to="#"
                    className="text-decoration-underline pe-none opacity-50"
                    onClick={() => {

                        // dispatch(questionairreActions.setCaseID({ caseID: dashboardData?.caseId }));
                    }}
                >
                    View All
                </Link>
            </Card.Header>
            <Card.Body className="p-3 pt-0">
                <ul className="list-unstyled">
                    {data?.map((dataItems: any, index: number) => {
                        const isLastItem = index === data?.length - 1;
                        const classNotForLastChild = isLastItem ? '' : 'border-bottom pb-2 mb-2';

                        return (
                            <li key={dataItems.id} className={`d-flex gap-2 ${classNotForLastChild}`}>
                                <div className="me-auto">{dataItems.count} {dataItems.title}</div>
                                <div className={`text-end ${dataItems.className}`}>{dataItems.status}</div>
                            </li>
                        )
                    })}
                    
                </ul>
            </Card.Body>
        </Card>
    )
}

export default Questionnaires
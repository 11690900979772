import { ThunkDispatch } from '@reduxjs/toolkit'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { FC, useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../../../components/common/Loader'
import DataGridCommon from '../../../../../components/common/dataGrid/DataGridCommon'
import { HIDE_TABLE_PAGINATOR } from '../../../../../constants/global'
import appConfig from '../../../../../helpers/config'
import { getQuestionnairesListAction } from '../../../../../redux/companyQuestionnaireModule/questionnaireSlice'

interface IQuestionnairesTab {
    caseData?: any;
}
const QuestionnairesTab: FC<IQuestionnairesTab> = ({
    caseData,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const caseDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);
    const caseID = useSelector((state: any) => state?.companyCaseSlice?.caseID);
    const questionnaireDataRedux = useSelector((state: any) => state?.companyQuestionnaireSlice?.questionnaire);
    const isLoading = useSelector((state: any) => state.questionairreSlice.loading);
    const [questionnaireData, setQuestionnaireData]: any = useState<any>([]);
    const [allIndexes, setAllIndexes] = useState<any>([])
    const [isDataReady, setIsDataReady] = useState(false);


    useEffect(() => {
        if(questionnaireDataRedux) {
            setQuestionnaireData(questionnaireDataRedux)
        }
    }, [questionnaireDataRedux]);

    useEffect(() => {
        if (!caseDetailsData || !caseID) return;
        GetQuestionnaireList();
    }, [caseDetailsData]);

    useEffect(() => {
        if (questionnaireData && questionnaireData?.length > 0) {
            const indexes = questionnaireData?.map((_: any, index: number) => index);
            setAllIndexes(indexes);
            setIsDataReady(true); // Set to true when data is ready
        }
    }, [questionnaireData]);

    const GetQuestionnaireList = () => {
        const payload = {
            search: "",
            case_id: caseID,
            petitioner_id: caseDetailsData?.case_petitioner_info?.[0]?.petitioner_id ? caseDetailsData?.case_petitioner_info?.[0]?.petitioner_id : ""
        }
        dispatch(getQuestionnairesListAction(payload));
    }

    const statusColTemplate = (rowData: any) => {
        // Set color based on status
        let variantBg = '';
        switch (rowData?.questionnaire_status_client) {
            case 'Completed':
                variantBg = 'bg-custom-success-subtle text-custom-success';
                break;
            case 'In Progress':
                variantBg = 'bg-custom-warning-subtle text-custom-warning';
                break;
            case 'Pending':
                variantBg = 'bg-custom-primary-subtle text-custom-secondary';
                break;
            default:
                variantBg = 'bg-custom-danger-subtle text-custom-danger';
        }
        return (
            <span
                className={`align-middle d-inline-block custom-min-width-90 py-1 rounded-1 text-center ${variantBg}`}
            >
                {rowData?.questionnaire_status_client}
            </span>
        )
    }

    // Table Columns
    const columns = [
        {
            field: "questionnaire_name",
            header: "Questionnaire",
            body: (rowData:any) => <Link className='text-truncate mw-100 d-inline-block align-middle' to={`${appConfig.basePath}/cases/view/${caseID}/${rowData?.questionnaire_id}`}>{rowData?.questionnaire_name}</Link>
        },
        {
            field: "questionnaire_description",
            header: "Description",
        },
        {
            field: "status",
            header: "Status",
            width: "200px",
            body: statusColTemplate,
            headerClassName: "theme-table-cell-center",
            bodyClassName: "text-center",
        },
    ];

    const renderAccordion = () => {
        return questionnaireData?.length > 0 && isDataReady ? (
            // <Accordion multiple activeIndex={allIndexes}>
                // {
                    questionnaireData?.map((questionnaireTableData: any) => {
                        return (
                            // <AccordionTab
                            //     key={questionnaireTableData?.questionnaire_id}
                            //     headerTemplate={
                            //         <Stack direction="horizontal" gap={2} className="flex-fill pe-3 mw-1">
                            //             <div className="d-inline-flex theme-accordion-title">
                            //                 <h5 className="text-primary fw-bold mb-0 mw-100 pe-1 text-truncate">
                            //                     {questionnaireTableData?.title}:{" "}
                            //                     <span className="fw-normal text-capitalize">
                            //                         {questionnaireTableData?.userName?.toLowerCase()}
                            //                     </span>
                            //                 </h5>
                            //             </div>
                            //         </Stack>
                            //     }
                            // >
                                <div className="theme-accordion-data">
                                    <DataGridCommon
                                        columns={columns}
                                        data={questionnaireTableData.content}
                                        HideTablePaginator={HIDE_TABLE_PAGINATOR}
                                    />
                                </div>
                            // </AccordionTab>
                        )
                    })
                // }
            // </Accordion>
        ) : null;
    };

    return (
        <>
            <Loader isLoading={isLoading} classCustom={'theme-loader-z-index'} />
            <div className="theme-tabs-table mb-4">
                <div className="theme-accordion-cover">
                    {
                        renderAccordion()
                    }
                    {questionnaireData?.length === 0 ? 'No questionnaire found' : ''}
                </div>
            </div>
        </>
    )
}

export default QuestionnairesTab
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { useEffect, useMemo, useRef, useState } from "react";
import { Breadcrumb, Button, Card, Col, ProgressBar, Row, Stack } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { MdEdit, MdPrint } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { ThunkDispatch } from "@reduxjs/toolkit";

import Loader from "../../../../../../components/common/Loader";
import { SvgIcons } from "../../../../../../components/common/SvgIcons";
import InfoTooltip from "../../../../../../components/common/InfoTooltip";
import CustomCheckbox from "../../../../../../components/common/CustomCheckbox";
import ValidationModal from "../modals/ValidationModal";
import DeleteModal from "../../../../../../components/common/DeleteModal";
import moment from "moment";
import ConfirmQuestionnaireModal from "../modals/ConfirmQuestionnaireModal";
import { DATE_FORMAT } from "../../../../../../constants/global";
import appConfig from "../../../../../../helpers/config";
import { viewClientQuestionnaaireAction, companyQuestionairreActions, notApplicableCaseQuestionnnaireAction, submitCaseQuestionnnaireAction } from "../../../../../../redux/companyQuestionnaireModule/questionnaireSlice";

const CrmQuestionnaireForm = () => {
    const params = useParams();
    const navigate = useNavigate();
    const case_id = params.caseId;
    const questionnaire_id = params.questionnaireid;
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoading = useSelector((state: any) => state.companyQuestionnaireSlice.loading);
    const lastSavedSectionID = useSelector((state: any) => state.companyQuestionnaireSlice.lastSavedSectionID);
    const [validationModalShow, setValidationModalShow] = useState(false);
    const [sectionData, setSectionData]: any = useState({});
    const [validationData, setValidationData]: any = useState([]);
    const [completedSections, setCompletedSections]: any = useState(0);
    const [totalSection, setTotalSection]: any = useState(100);
    const [isSectionSelected, setIsSectionSelected]: any = useState(true);
    const [sectionDataModal, setSectionDataModal]: any = useState({});
    const [activeIndex, setActiveIndex]: any = useState({
        show: true,
        index: []
    });
    const [showNotApplicableModal, setShowNotApplicableModal]: any = useState(false);
    const [showNotapplicableData, setShowNotapplicableData]: any = useState(false);
    const [deleteShowValidation, setDeleteShowValidation] = useState(false);
    const [isDataChanged, setIsDataChanged]: any = useState(false);
    const [selectedSection, setSelectedSection]: any = useState(null);

    const [questionnaireFormData, setQuestionnaireFormData]: any = useState({});
    const [editSections, setEditSections] = useState<any>([]);
    const [confirmQuestionnaireModalShow, setConfirmQuestionnaireModalShow] = useState<boolean>(false);
    const [currentSectionIndex, setCurrentSectionIndex] = useState<any>('');
    const [isDataChangedWithId, setIsDataChangedWithId]: any = useState({});
    const [datepickerObj, setDatePickerObj]: any = useState([]);

    const caseDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);

    useEffect(() => {
        // dispatch(companyQuestionairreActions.saveLastSection({ lastSavedSectionID: null }));
        localStorage.removeItem("selectedSectionId")
        GetQuestionnaireData();
    }, []);

    useEffect(() => {
        let _sectionData: any = { ...sectionData };
        const selectedSectionId: any = localStorage.getItem("selectedSectionId");
        questionnaireFormData?.case_sections?.forEach((_element: any) => {
            if (selectedSectionId !== null && _element.section_id !== parseInt(selectedSectionId)) {
                return;
            }
            _sectionData[_element.section_id] = {
                section_id: _element.section_id,
                section_title: _element.section_name,
                description: _element.section_help_text,
                questionnaire_id: questionnaireFormData.questionnaire_id,
                case_questions: [],
                not_applicable: _element.not_applicable,
                completed: _element.completed
            }
            _element.case_questions.forEach((ques: any) => {
                // debugger
                let value: any = "";
                let _validation: any = [];
                if (ques.question_type === 'MultiSelect') {
                    if (_element.originalnotApplicable) {
                        value = ques.default_value ? ques?.default_value?.split(",") : [];
                    } else {
                        if (_element?.originalCompleted) {
                            value = ques.submitted_answer ? ques?.submitted_answer : [];
                        } else {
                            value = ques.default_value ? ques?.default_value?.split(",") : [];
                        }
                    }
                } else if (ques.question_type === 'DatePicker') {
                    setDatePickerObj((prev:any) => [...prev, { section_id: ques.section_id, question_id: ques.question_id }]);
                    if (_element.originalnotApplicable) {
                        value = new Date(ques.default_value) ?? "";
                    } else {
                        if (_element?.originalCompleted) {
                            const dateReminder = ques.submitted_answer ? moment(new Date(ques.submitted_answer).toDateString()).format(DATE_FORMAT):"";
                            value = dateReminder ?? "";
                            // value = ques.submitted_answer ? new Date(ques.submitted_answer) ?? "" : "";
                        } else {
                            const dateReminder = ques.default_value ? moment(new Date(ques.default_value).toDateString()).format(DATE_FORMAT):"";
                            value = dateReminder ?? "";
                            // value = new Date(ques.default_value) ?? "";
                        }
                    }
                } else if (ques.question_type === 'Checkbox') {
                    let tempdefaultValue = []
                    tempdefaultValue.push(ques.default_value);
                    if (_element.originalnotApplicable) {
                        value = ques.default_value ? tempdefaultValue : [];
                    } else {
                        if (_element?.originalCompleted) {
                            let tempSubmitedAnswer = [];
                            tempSubmitedAnswer.push(ques.submitted_answer)
                            value = ques.submitted_answer ? ques.submitted_answer : [];
                        } else {
                            value = ques.default_value ? tempdefaultValue : [];
                        }
                    }
                } else {
                    if (_element.originalnotApplicable) {
                        value = ques.default_value ?? "";
                    } else {
                        if (_element?.originalCompleted) {
                            value = ques.submitted_answer ?? "";
                        } else {
                            value = ques.default_value ?? "";
                        }
                    }
                }
                if (ques.required_type === 'phone') {
                    _validation.push("Field value must be numeric");
                } else if (ques.required_type === 'email') {
                    _validation.push("Field value must be email");
                }
                _sectionData[_element.section_id].case_questions.push({
                    "question_id": ques.question_id,
                    "section_id": _element.section_id,
                    "question_title": ques.question_title,
                    "description": ques.description,
                    "default_value": ques.default_value,
                    "placeholder": ques.placeholder,
                    "option_values": ques.option_values,
                    "question_type": ques.question_type,
                    "is_required": ques.is_required,
                    "required_type": ques.required_type,
                    "value": value,
                    "is_phoneNo": ques.required_type === 'phone' ? true : false,
                    "is_email": ques.required_type === 'email' ? true : false
                })
            });
        });
        setSectionData(_sectionData);
    }, [questionnaireFormData]);

    const GetQuestionnaireData = () => {
        dispatch(companyQuestionairreActions.startLoader(true));
        const masterPayload = {
            case_id,
            questionnaire_id
        };
        let count = 0;
        dispatch(viewClientQuestionnaaireAction(masterPayload)).then((data: any) => {
            // let _data = data.payload.data[0];
            let _data = data.payload.data;
            const selectedSectionId: any = localStorage.getItem("selectedSectionId");
            _data.case_sections.forEach((el: any, index: any) => {
                if (localStorage.getItem("selectedSectionId") && el.section_id !== parseInt(selectedSectionId, 10)) {
                    const _data = { ...questionnaireFormData };
                    if (!data) return;
                    const _index = _data?.case_sections?.findIndex((i: { section_id: any; }) => i.section_id === el.section_id);
                    el.completed = _data?.case_sections?.[_index]?.completed;
                    el.originalCompleted = _data?.case_sections?.[_index]?.originalCompleted;
                    el.not_applicable = _data?.case_sections?.[_index]?.not_applicable;
                    el.originalnotApplicable = _data?.case_sections?.[_index]?.originalnotApplicable;
                } else {
                    el.originalCompleted = el.completed;
                    el.originalnotApplicable = el.not_applicable;
                }
                if (el.originalCompleted) {
                    count = count + 1;
                }
            })
            setCompletedSections(count);
            setTotalSection(_data.case_sections.length);
            setQuestionnaireFormData(_data)
            dispatch(companyQuestionairreActions.stopLoader(true));
        }).catch((err: any) => {
            console.log(err);
        });
    }

    // Accordion Edit Button Click Handler
    const handleAccordionEditClick = (id: any, event: any, sectionIndex: any) => {
        if(!editSections.includes(id)){
            let sectionIDs = editSections;
            sectionIDs.push(id)
            setEditSections(sectionIDs)
        }

        //If Current Tab Opened and Clicking on Edit Button
        if (activeIndex?.index?.includes(sectionIndex)) {
            event.stopPropagation();
            event.preventDefault();
        }

        let _questionnaireFormData = { ...questionnaireFormData }
        _questionnaireFormData.case_sections.forEach((section: any) => {
            if (section.section_id === id) {
                section.completed = false;
                if (!section.not_applicable) {
                    section.originalnotApplicable = false;
                }
                localStorage.setItem("selectedSectionId", section.section_id)
                // dispatch(companyQuestionairreActions.saveLastSection({ lastSavedSectionID: section.section_id }));
            }
        });
        setQuestionnaireFormData(_questionnaireFormData);
    };

    // Get all data for expend all accordions
    //const allIndexes = [0] //questionnaireFormData?.sections.map((item:any, index:any) => index);

    // JHANDLE INPUT AREA
    const handleInputChange = (e: any, field: any, sectionId: any, questionIndex: any) => {
        let _sectionData: any = { ...sectionData };
        _sectionData[sectionId].case_questions[questionIndex].value = e.target.value;
        setSectionData(_sectionData);
        if(!editSections.includes(sectionId)){
            let tempArr = editSections;
            tempArr.push(sectionId)
            setEditSections(tempArr);
        }
        setIsDataChanged(true);
        setIsDataChangedWithId((prevIsDataChangedWithId:any) => ({...prevIsDataChangedWithId, [sectionId]: true }));
    };

    // HANDLE ALL OTHER INPUT EXPECT TEXTBOX,TEXTAREA AND CHECKBOX
    const handleAllOtherInput = (e: any, field: any, sectionId: any, questionIndex: any) => {
        let _sectionData: any = { ...sectionData };
        _sectionData[sectionId].case_questions[questionIndex].value = e.value ?? "";
        setSectionData(_sectionData);
        if(!editSections.includes(sectionId)){
            let tempArr = editSections;
            tempArr.push(sectionId)
            setEditSections(tempArr);
        }
        setIsDataChanged(true)
        setIsDataChangedWithId((prevIsDataChangedWithId:any) => ({...prevIsDataChangedWithId, [sectionId]: true }));
    };

    // HANDLE CHECKBOX INPUT VALUE
    const handleCheckBoxInput = (e: any, field: any, sectionId: any, questionIndex: any) => {
        let _sectionData: any = { ...sectionData };
        if (e.checked) {
            _sectionData[sectionId].case_questions[questionIndex].value?.push(e.value);
        } else {
            _sectionData[sectionId].case_questions[questionIndex].value = _sectionData[sectionId].case_questions[questionIndex].value?.filter((i: any) => { return i !== e.value });
        }
        setSectionData(_sectionData);
        if(!editSections.includes(sectionId)){
            let tempArr = editSections;
            tempArr.push(sectionId)
            setEditSections(tempArr);
        }
        setIsDataChanged(true);
        setIsDataChangedWithId((prevIsDataChangedWithId:any) => ({...prevIsDataChangedWithId, [sectionId]: true }));
    };

    // OPEN NOT APPLICABLE POPUP

    const opemModalForNotApplicable = (event: any, section: any, sectionIndex:any) => {
        setCurrentSectionIndex(sectionIndex);
        let _data: any = {};
        _data.section = section;
        if (event.checked) {
            _data.title = "Skip this Section";
            _data.content = "Are you sure you want to skip this section?";
            _data.checked = true;
        } else {
            _data.title = "Include this Section";
            _data.content = "Are you sure you want to include this section?";
            _data.checked = false;
        }
        setShowNotapplicableData(_data);
        setShowNotApplicableModal(true);
    }

    const closeActiveIndex = (activeSectionIndex:any) => {
        const dummyIndex = activeIndex.index;
        setActiveIndex({
          show: true,
          index: dummyIndex.filter((index: number) => index !== activeSectionIndex)
        });
    };

    //HANDLE NOT APPLICABLE CHECKBOX
    const notApplicableCheckHandler = (event: any, sectionId: any) => {
        dispatch(companyQuestionairreActions.startLoader(true));
        const masterPayload = {
            "case_id": case_id,
            "section_id": sectionId,
            "not_applicable": event
        };
        localStorage.setItem("selectedSectionId", sectionId);
        dispatch(notApplicableCaseQuestionnnaireAction(masterPayload)).then((data: any) => {
            GetQuestionnaireData();
            dispatch(companyQuestionairreActions.stopLoader(true));
            closeActiveIndex(currentSectionIndex);
            setShowNotApplicableModal(false)
        }).catch((err: any) => {
            console.log(err);
        });
    };

    //HANDLE SAVE AND CONTINUE
    const saveAndContinue = (data: any, sectionIndex:any) => {
        setCurrentSectionIndex(sectionIndex);
        const errorList = validationManagement(data);
        if (errorList[0].messages.length > 0) {
            setIsSectionSelected(true);
            setSectionDataModal(data);
            setValidationModalShow(true);
            return;
        } else {
            proceedSection(data, sectionIndex);
        }
    }

    // VALIDAITON OPERATION ON SECTION DATA
    const validationManagement = (data: any) => {
        let _sectionData: any = { ...sectionData };
        const selectedData = _sectionData[data.section_id]
        let errorList: any = [{
            id: selectedData.section_id,
            title: selectedData.section_title,
            messages: []
        }];
        const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        selectedData.case_questions.forEach((field: any) => {
            // CHECKING IF FIELD IS REQUIRED 
            if (field.is_required && !field.value) {
                errorList[0].messages.push(
                    `${field.question_title}`
                );
            } else {
                // CHECKING IF FIELD IS MULTI SELECT AND VALUE IS BLANK
                if (field.question_type === 'MultiSelect' && field.value.length === 0 && field.is_required) {
                    errorList[0].messages.push(
                        `${field.question_title}`
                    );
                }
                // CHECKING IF FIELD IS INPUT AND TYPE IS PHONE NO
                if (field.required_type === 'phone' && isNaN(field.value)) {
                    errorList[0].messages.push(
                        `${field.question_title}`
                    );
                }
                // CHECKING IF FIELD IS INPUT AND TYPE IS EMAIL
                if (field.required_type === 'email' && field.value !== '' && !emailRegex.test(field.value)) {
                    errorList[0].messages.push(
                        `${field.question_title}`
                    );
                }
            }
        });
        setValidationData(errorList);
        return errorList;
    }

    // SUBMIT HANDLE
    const submitHandler = () => {
        setIsSectionSelected(false);
        let _sectionData: any = { ...sectionData };
        let errorList: any = [];
        const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let _index = 0;
        let isValid = true;
        for (const key in _sectionData) {
            const element = _sectionData[key];
            if (!element.not_applicable) {
                _index = _index + 1;
                errorList.push({
                    id: element.section_id,
                    title: element.section_title,
                    messages: []
                });

                // eslint-disable-next-line no-loop-func
                element.case_questions.forEach((field: any) => {
                    // CHECKING IF FIELD IS REQUIRED 
                    if (field.is_required && !field.value) {
                        isValid = false;
                        errorList[_index - 1].messages.push(
                            `${field.question_title}`
                        );
                    } else {
                        // CHECKING IF FIELD IS MULTI SELECT AND VALUE IS BLANK
                        if ((field.question_type === 'MultiSelect' || field.question_type === 'Checkbox') && field.value.length === 0 && field.is_required) {
                            isValid = false;
                            errorList[_index - 1].messages.push(
                                `${field.question_title}`
                            );
                        }
                        // CHECKING IF FIELD IS INPUT AND TYPE IS PHONE NO
                        if (field.required_type === 'phone' && isNaN(field.value)) {
                            isValid = false;
                            errorList[_index - 1].messages.push(
                                `${field.question_title}`
                            );
                        }
                        // CHECKING IF FIELD IS INPUT AND TYPE IS EMAIL
                        if (field.required_type === 'email' && field.value !== '' && !emailRegex.test(field.value)) {
                            isValid = false;
                            errorList[_index - 1].messages.push(
                                `${field.question_title}`
                            );
                        }
                    }
                });
            }
        }
        
        if (isValid) {
            setConfirmQuestionnaireModalShow(true)
        } else {
            setValidationData(errorList);
            setValidationModalShow(true);
        }
    }

    // PROCEED FOR THE SECTION EVEN WITH ERRORS
    const proceedSection = (data: any, sectionIndex:any) => {
        if (!data) return;
        setValidationModalShow(false);
        let _sectionData: any = { ...sectionData };
        const selectedData = _sectionData[data.section_id]
        let caseSection = []
        const element = selectedData;
        caseSection.push({
            "section_id": element.section_id,
            "case_questions": element.case_questions.map((i: any) => {
                let value = i.question_type == "DatePicker" ? moment(new Date(i.value).toDateString()).format(DATE_FORMAT): i.value;
                return {
                    question_id: i.question_id,
                    submitted_answer: value ?? ""
                }
            })
        })
        const payload = {
            "case_id": questionnaireFormData.case_id,
            "questionnaire_id": questionnaireFormData.questionnaire_id,
            "partial_submit": true,
            "case_sections": caseSection
        };

        dispatch(companyQuestionairreActions.startLoader(true));
        localStorage.setItem("selectedSectionId", payload.case_sections[0].section_id);
        //dispatch(companyQuestionairreActions.saveLastSection({ lastSavedSectionID: element.section_id }));
        let sectionIDs = editSections.filter((element: any) => element != data.section_id);
        setEditSections(sectionIDs)
        let tempIsDataChangedWithId = isDataChangedWithId
        delete tempIsDataChangedWithId[data.section_id];
        setIsDataChangedWithId(tempIsDataChangedWithId);
        dispatch(submitCaseQuestionnnaireAction(payload)).then((resp: any) => {
            GetQuestionnaireData();
            dispatch(companyQuestionairreActions.stopLoader(true));
            closeActiveIndex(sectionIndex);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const proceedPage = () => {
        let _sectionData: any = { ...sectionData };
        const payload: any = {
            "case_id": questionnaireFormData.case_id,
            "questionnaire_id": questionnaireFormData.questionnaire_id,
            "partial_submit": false,
            "case_sections": []
        }
        let caseSection = [];
        for (const key in _sectionData) {
            const element: any = _sectionData[key];
            caseSection.push({
                "section_id": element.section_id,
                "case_questions": element.case_questions.map((i: any) => {
                    return {
                        question_id: i.question_id,
                        submitted_answer: i.value
                    }
                })
            });
        }
        payload.case_sections = caseSection;
        dispatch(companyQuestionairreActions.startLoader(true));
        dispatch(submitCaseQuestionnnaireAction(payload)).then((data: any) => {
            navigate(`${appConfig.basePath}/cases`, {
                state: { reduxStateStage: true }
            });
            dispatch(companyQuestionairreActions.stopLoader(true));
        }).catch((err: any) => {
            console.log(err);
        });

    }

    // RENDERING ALL INPUTS 
    const renderSwitch = (field: any, sectionId: any, questionIndex: any, section: any) => {
        switch (field.question_type) {
            case 'TextBox':
                return renderTextBox(field, sectionId, questionIndex, section)
            case 'Radio':
                return renderRadio(field, sectionId, questionIndex, section)
            case 'Checkbox':
                return renderCheckbox(field, sectionId, questionIndex, section)
            case 'Dropdown':
                return renderSelect(field, sectionId, questionIndex, section)
            case 'MultiSelect':
                return renderMultiSelect(field, sectionId, questionIndex, section)
            case 'DatePicker':
                return renderDatePicker(field, sectionId, questionIndex, section)
            case 'TextArea':
                return renderTextArea(field, sectionId, questionIndex, section)
        }
    }

    const renderTextBox = (field: any, sectionId: any, questionIndex: any, section: any) => {
        return (
            <li key={field.name} className="px-20 py-2 py-sm-1">
                <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                    <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                        <div className="text-break ">
                            {field.question_title}
                            {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                        </div>
                        {
                        field.description ? 
                            <InfoTooltip
                            placement="top"
                            className="d-inline-flex p-0 text-secondary-emphasis"
                            tooltipTitle={ <FaInfoCircle size={12} /> }
                            tooltipData={field.description}  
                            /> 
                        : ''
                        }
                    </div>
                    <div className="max-w-350 flex-fill">
                        <InputText
                            className="w-100 hide-input-print"
                            id={field.question_title}
                            name={field.question_title}
                            value={sectionData[sectionId]?.case_questions[questionIndex]?.value}
                            onChange={(e: any) => handleInputChange(e, field, sectionId, questionIndex)}
                            placeholder={field.placeholder}
                            disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                        />
                        {/* Only render at print time */}
                        <div className="text-input-clone-print">
                            {sectionData[sectionId]?.case_questions[questionIndex]?.value}
                        </div>
                    </div>
                </Stack>
            </li>)
    }

    const renderCheckbox = (field: any, sectionId: any, questionIndex: any, section: any) => {
        return (
            <>
                <li key={field.name} className="px-20 py-2 py-sm-1">
                    <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                        <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                            <div className="text-break ">
                                {field.question_title}
                                {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                            </div>
                            {
                            field.description ? 
                                <InfoTooltip
                                placement="top"
                                className="d-inline-flex p-0 text-secondary-emphasis"
                                tooltipTitle={ <FaInfoCircle size={12} /> }
                                tooltipData={field.description}  
                                /> 
                            : ''
                            }
                        </div>
                        {
                            field.option_values.map((i: any) => {
                                return (
                                    <div className="d-inline-flex align-item-center gap-1 me-3">
                                        <div className="radio-cover d-flex align-items-center">
                                            <Checkbox
                                                inputId={field.question_id}
                                                name={field.question_id}
                                                value={i}
                                                onChange={(e: any) => handleCheckBoxInput(e, field, sectionId, questionIndex)}
                                                checked={sectionData[sectionId]?.case_questions[questionIndex]?.value?.some((item: any) => item === i)}
                                                placeholder={field.placeholder}
                                                disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                                            />
                                            <span className={sectionData[sectionId]?.case_questions[questionIndex]?.value.some((item: any) => item === i) ? "check-image" : 'radio-image-hide' } >
                                                <svg version="1.1" id="Capa_1" x="0px" y="0px"
                                                    width="459px" height="459px" viewBox="0 0 459 459"  >
                                                <g>
                                                    <g id="check-box">
                                                    <path d="M408,0H51C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V51C459,22.95,436.05,0,408,0z
                                                        M178.5,357L51,229.5l35.7-35.7l91.8,91.8L372.3,91.8l35.7,35.7L178.5,357z"/>
                                                    </g>
                                                </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <label
                                            htmlFor={field.question_id}
                                            className={`form-label mb-0 small`}
                                        >
                                            {i}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </Stack>
                </li>

            </>
        )
    }

    const renderRadio = (field: any, sectionId: any, questionIndex: any, section: any) => {
        return (
            <>
                <li key={field.name} className="px-20 py-2 py-sm-1">
                    <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                        <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                            <div className="text-break ">
                                {field.question_title}
                                {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                            </div>
                            {
                            field.description ? 
                                <InfoTooltip
                                placement="top"
                                className="d-inline-flex p-0 text-secondary-emphasis"
                                tooltipTitle={ <FaInfoCircle size={12} /> }
                                tooltipData={field.description}  
                                /> 
                            : ''
                            }
                        </div>
                        {
                            field.option_values.map((i: any) => {
                                return (
                                    <>
                                        <div className="radio-cover d-flex align-items-center">
                                            <RadioButton
                                                name={field.question_title[i]}
                                                id={field.question_title[i]}
                                                value={i}
                                                onChange={(e: any) => handleAllOtherInput(e, field, sectionId, questionIndex)}
                                                checked={sectionData[sectionId]?.case_questions[questionIndex]?.value === i}
                                                placeholder={field.placeholder}
                                                disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                                            />
                                            <span className={sectionData[sectionId]?.case_questions[questionIndex]?.value === i ? "check-image" : 'radio-image-hide' } >
                                                <svg width="800px" height="800px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                    <title>ic_fluent_radio_button_24_filled</title>
                                                    <desc>Created with Sketch.</desc>
                                                    <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <g id="ic_fluent_radio_button_24_filled" fill="#212121" fill-rule="nonzero">
                                                            <path d="M12.0000002,1.99896738 C17.523704,1.99896738 22.0015507,6.47681407 22.0015507,12.0005179 C22.0015507,17.5242217 17.523704,22.0020684 12.0000002,22.0020684 C6.47629639,22.0020684 1.99844971,17.5242217 1.99844971,12.0005179 C1.99844971,6.47681407 6.47629639,1.99896738 12.0000002,1.99896738 Z M12.0000002,3.49896738 C7.30472352,3.49896738 3.49844971,7.30524119 3.49844971,12.0005179 C3.49844971,16.6957946 7.30472352,20.5020684 12.0000002,20.5020684 C16.6952769,20.5020684 20.5015507,16.6957946 20.5015507,12.0005179 C20.5015507,7.30524119 16.6952769,3.49896738 12.0000002,3.49896738 Z M11.9965637,5.99896738 C15.3092306,5.99896738 17.9946777,8.68441449 17.9946777,11.9970814 C17.9946777,15.3097483 15.3092306,17.9951954 11.9965637,17.9951954 C8.68389682,17.9951954 5.99844971,15.3097483 5.99844971,11.9970814 C5.99844971,8.68441449 8.68389682,5.99896738 11.9965637,5.99896738 Z" id="🎨-Color">
                                                            </path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <label className="form-label mb-0 fs-14">
                                            {i}
                                        </label>
                                    </>
                                )
                            })
                        }
                    </Stack >
                </li>

            </>
        )
    }

    const renderSelect = (field: any, sectionId: any, questionIndex: any, section: any) => {
        const options: any = [];
        field.option_values.forEach((element: any) => {
            options.push({
                label: element, value: element
            })
        });
        return (
            <>
                <li key={field.name} className="px-20 py-2 py-sm-1">
                    <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                        <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                            <div className="text-break ">
                                {field.question_title}
                                {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                            </div>
                            {
                            field.description ? 
                                <InfoTooltip
                                placement="top"
                                className="d-inline-flex p-0 text-secondary-emphasis"
                                tooltipTitle={ <FaInfoCircle size={12} /> }
                                tooltipData={field.description}  
                                /> 
                            : ''
                            }
                        </div>
                        {
                            <div className="max-w-350 flex-fill">
                                <Dropdown
                                    className="w-100"
                                    id={field.id}
                                    inputId={field.id}
                                    name={field.id}
                                    value={sectionData[sectionId]?.case_questions[questionIndex]?.value}
                                    options={options}
                                    optionLabel="label"
                                    placeholder={field.placeholder}
                                    onChange={(e: any) => handleAllOtherInput(e, field, sectionId, questionIndex)}
                                    filterPlaceholder={field.placeholder}
                                    showClear={sectionData[sectionId]?.case_questions[questionIndex]?.value ? true : false}
                                    disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                                />
                            </div>
                        }
                    </Stack >
                </li>
            </>
        )
    }

    const renderMultiSelect = (field: any, sectionId: any, questionIndex: any, section: any) => {
        const options: any = [];
        field.option_values.forEach((element: any) => {
            options.push({
                label: element, value: element
            })
        });
        return (
            <>
                <li key={field.name} className="px-20 py-2 py-sm-1">
                    <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                        <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                            <div className="text-break ">
                                {field.question_title}
                                {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                            </div>
                            {
                            field.description ? 
                                <InfoTooltip
                                placement="top"
                                className="d-inline-flex p-0 text-secondary-emphasis"
                                tooltipTitle={ <FaInfoCircle size={12} /> }
                                tooltipData={field.description}  
                                /> 
                            : ''
                            }
                        </div>
                        {
                            <div className="max-w-350 flex-fill">
                                <MultiSelect
                                    className="w-100"
                                    inputId={field.id}
                                    value={sectionData[sectionId]?.case_questions[questionIndex]?.value}
                                    onChange={(e: any) => handleAllOtherInput(e, field, sectionId, questionIndex)}
                                    options={options}
                                    optionLabel="label"
                                    placeholder={field.placeholder}
                                    maxSelectedLabels={3}
                                    filter={true}
                                    filterPlaceholder={field.placeholder}
                                    showClear={sectionData[sectionId]?.case_questions[questionIndex]?.value ? true : false}
                                    disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                                />
                            </div>
                        }
                    </Stack >
                </li>
            </>
        )
    }

    const renderTextArea = (field: any, sectionId: any, questionIndex: any, section: any) => {
        return (
            <li key={field.name} className="px-20 py-2 py-sm-1">
                <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                    <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                        <div className="text-break ">
                            {field.question_title}
                            {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                        </div>
                        {
                        field.description ? 
                            <InfoTooltip
                            placement="top"
                            className="d-inline-flex p-0 text-secondary-emphasis"
                            tooltipTitle={ <FaInfoCircle size={12} /> }
                            tooltipData={field.description}  
                            /> 
                        : ''
                        }
                    </div>
                    <div className="max-w-350 flex-fill">
                        <InputTextarea
                            className="w-100 hide-input-print "
                            id={field.question_title}
                            name={field.question_title}
                            data-section={field.id}
                            value={sectionData[sectionId]?.case_questions[questionIndex]?.value}
                            onChange={(e: any) => handleInputChange(e, field, sectionId, questionIndex)}
                            placeholder={field.placeholder}
                            disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                        />
                        {/* Only render at print time */}
                        <div className="text-input-clone-print">
                            {sectionData[sectionId]?.case_questions[questionIndex]?.value}
                        </div>
                    </div>
                </Stack>
            </li>)
    }

    const renderDatePicker = (field: any, sectionId: any, questionIndex: any, section: any) => {
        const validDate = sectionData[sectionId]?.case_questions[questionIndex]?.value && sectionData[sectionId]?.case_questions[questionIndex]?.value !== "Invalid date";
        const dateValue = validDate ? new Date(sectionData[sectionId]?.case_questions[questionIndex]?.value) : null;
        // const dateValue = new Date(sectionData[sectionId]?.case_questions[questionIndex]?.value);
        return (
            <li key={field.name} className="px-20 py-2 py-sm-1">
                <Stack direction="horizontal" gap={1} className="flex-wrap min-h-50">
                    <div className="w-220 d-flex align-items-center lh-sm gap-1 pe-3">
                        <div className="text-break ">
                            {field.question_title}
                            {sectionData[sectionId]?.case_questions[questionIndex]?.is_required ? <span>*</span> : ""}
                        </div>
                        {
                        field.description ? 
                            <InfoTooltip
                            placement="top"
                            className="d-inline-flex p-0 text-secondary-emphasis"
                            tooltipTitle={ <FaInfoCircle size={12} /> }
                            tooltipData={field.description}  
                            /> 
                        : ''
                        }
                    </div>
                    <div className={`max-w-350 flex-fill calendar-${sectionId}-${field.question_id}`} data-target-value={dateValue}>
                        <Calendar
                            className="w-100"
                            id={field.id}
                            placeholder={field.placeholder}
                            value={dateValue}//sectionData[sectionId]?.case_questions[questionIndex]?.value}
                            onChange={(e: any) => handleAllOtherInput(e, field, sectionId, questionIndex)}
                            showButtonBar={true}
                            dateFormat="mm/dd/yy"
                            disabled={section?.not_applicable || section?.completed || questionnaireFormData?.questionnaire_status === 'Reviewed'}
                        />
                    </div>
                </Stack>
            </li>)
    }

    const ValidateChanges = () => {
        let isValid = true;
        let keysOfDataChanged = Object.keys(isDataChangedWithId);
        if (keysOfDataChanged.length > 0 && editSections.length > 0) {
            isValid = false;
            setDeleteShowValidation(true);
        }
        if (isValid) {
            GoBack();
        }
    }

    const GoBack = () => {
        navigate(`${appConfig.basePath}/cases`);
    }

    const modalConfirmButtonHandler = () => {
        proceedPage();
        setConfirmQuestionnaireModalShow(false)
    }

    //Print Handler
    const printAreaRef = useRef<any>(null);
    const printHandler = () => {
        setActiveIndex({
            show: false,
            index: []
        });
        setTimeout(() => {
            setActiveIndex({
                show: true,
                index: questionnaireFormData?.case_sections?.map((data: any, index: any) => index)
            });
            setTimeout(() => {
                let printContent = null
                const printAreaContent = printAreaRef?.current?.innerHTML;
                // Create a new div to hold the content
                printContent = document.createElement('div');
                printContent.classList.add('is-print-area');
                printContent.innerHTML = printAreaContent;

                for(const innerItem of datepickerObj) {
                    const newElelement = printContent.getElementsByClassName(`max-w-350 flex-fill calendar-${innerItem?.section_id}-${innerItem?.question_id}`)
                    const newCondi = newElelement?.length > 0 ? newElelement[0] : ''
                    if(newCondi) {
                      let newInput = newCondi.getElementsByTagName("input")[0];
                      let valueofDate = newCondi.getAttribute("data-target-value")
                      let convertedDate = valueofDate ? moment(valueofDate).format("MM/DD/YYYY") : ''
                      newInput?.setAttribute("value", convertedDate)
                    }
                }

                document.body.appendChild(printContent);

                // Do something with the content, e.g., send it to the printer 
                window.print();
                document.body.removeChild(printContent);
            }, 500);
        }, 100);

    }

    const showEmployeeName = () => {
        const name = [caseDetailsData?.case_client_info?.first_name, caseDetailsData?.case_client_info?.middle_name, caseDetailsData?.case_client_info?.last_name].filter(Boolean).join(" ")
        const customLabel = `${name} ${caseDetailsData?.case_type_info?.name} [${caseDetailsData?.file_number}]`

        return customLabel
    }

    return (
        <>
            <Loader classCustom={'theme-loader-z-index'} isLoading={isLoading} />
            <div className="d-flex flex-column pageContainer p-3 h-100 overflow-y-scroll">
                <div className="contentHeader p-1">
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap justify-content-between pb-2"
                    >
                        <h1 className="fw-semibold h4 mb-0">Case Management</h1>
                        <Breadcrumb className="fw-semibold">
                            <li className="breadcrumb-item">
                                <a className="text-decoration-none c-pointer" role="button" onClick={ValidateChanges}>
                                    Questionnaire
                                </a>
                            </li>
                            <Breadcrumb.Item active>View/Fill Questionnaire</Breadcrumb.Item>
                        </Breadcrumb>
                    </Stack>
                </div>
                <div className="flex-grow-1 pageContent position-relative pt-4 d-flex flex-column is-print-area" >
                    <Card className="bg-white shadow-lg border-0 theme-card-cover flex-grow-1">
                        <div className="theme-card-header px-1">
                            <Stack
                                direction="horizontal"
                                gap={3}
                                className="flex-wrap px-3 pt-3 pb-2"
                            >
                                <h5 className="mb-0 position-relative fw-normal me-auto theme-print-ps-0">
                                    <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1 theme-print-hide">
                                        <span className="theme-icon-box-inner text-center">
                                            {SvgIcons.caseManagementIcon}
                                        </span>
                                    </div>
                                    {questionnaireFormData?.questionnaire_name}
                                </h5>
                                <Stack
                                    direction="horizontal"
                                    gap={2}
                                    className="text-body-tertiary"
                                >
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        className="me-sm-1 theme-print-hide"
                                        onClick={(e) => printHandler()}
                                    >
                                        <MdPrint size={19} className="me-2" />
                                        Print
                                    </Button>
                                    <span className="fw-semibold">Progress:</span>
                                    <span>{completedSections}/{totalSection}</span>
                                    <div className="min-w-80 px-1">
                                        <ProgressBar
                                            now={completedSections / totalSection * 100}
                                            className="theme-custom-progressbar"
                                        />
                                    </div>
                                </Stack>
                            </Stack>
                        </div>

                        <div className="p-20" ref={printAreaRef}>
                            <Stack
                                direction="horizontal"
                                className="flex-wrap pb-1 align-items-start"
                            >
                                <Col md={4} xl={5} className="small mb-3 me-auto">
                                    <div className="mb-1 fw-bold">Employee Name</div>
                                    <div className="lh-sm text-capitalize">{showEmployeeName()}</div>
                                </Col>
                                <Col md={7} xl={6} className="small mb-3 me-sm-auto">
                                    <div className="mb-1 fw-bold">{questionnaireFormData?.questionnaire_name ? 'Questionnaire Title' : ''}</div>
                                    <div className="lh-sm">{questionnaireFormData?.questionnaire_name}</div>
                                </Col>
                            </Stack>

                            <div className="theme-accordion-cover">
                                {activeIndex?.show ?
                                    <Accordion
                                        multiple
                                        activeIndex={activeIndex?.index}
                                        onTabChange={(e) => setActiveIndex({show: true, index:e.index})}
                                    >
                                        {questionnaireFormData?.case_sections?.map((questionnaireData: any, sectionIndex: any) => {
                                            const { section_id, section_name, section_help_text, section_description } = questionnaireData;
                                            return (
                                                <AccordionTab
                                                    headerClassName={`${isDataChangedWithId[section_id] && editSections.includes(section_id)? 'custom-accordion-active' : ''}`}
                                                    key={section_id}
                                                    headerTemplate={
                                                        <Stack
                                                            direction="horizontal"
                                                            gap={2}
                                                            className="flex-fill pe-3 mw-1"
                                                        >
                                                            <Stack
                                                                className="me-auto mw-1 justify-content-center"
                                                            >
                                                                <div className="d-inline-flex theme-accordion-title">
                                                                    <h5 className="fw-semibold mb-0 mw-100 pe-1 text-truncate">{section_name}</h5>
                                                                    {section_help_text ?
                                                                        <InfoTooltip
                                                                            tooltipTitle={<FaInfoCircle size={12} />}
                                                                            tooltipData={section_help_text}
                                                                            className="text-secondary-emphasis lh-1 p-0"
                                                                            onClick={(event: any) => {
                                                                                event.stopPropagation();
                                                                                event.preventDefault();
                                                                            }}
                                                                        /> : ""}
                                                                </div>
                                                                <p className="mb-0 text-truncate">{section_description}</p>
                                                            </Stack>

                                                            {questionnaireData.completed && questionnaireFormData?.questionnaire_status !== 'Reviewed' ?
                                                                <InfoTooltip
                                                                    tooltipTitle={<MdEdit size={24} />}
                                                                    tooltipData="Edit"
                                                                    className="p-0 d-none d-md-inline-block"
                                                                    onClick={(event: any) => handleAccordionEditClick(section_id, event, sectionIndex)}
                                                                />
                                                                : ''}
                                                        </Stack>
                                                    }
                                                >
                                                    <div className="theme-accordion-data">
                                                        <ul className="list-unstyled theme-odd-even-list theme-disabled-in-mobile">
                                                            {
                                                                questionnaireData?.case_questions?.map((element: any, questionIndex: any) => {
                                                                    return (renderSwitch(element, questionnaireData.section_id, questionIndex, questionnaireData))
                                                                })
                                                            }
                                                        </ul>
                                                        <Stack
                                                            direction="horizontal"
                                                            gap={3}
                                                            className="flex-wrap gap-xl-4 mt-3 fw-semibold justify-content-sm-end d-none d-md-flex theme-print-hide"
                                                        >
                                                            <CustomCheckbox
                                                                controlId={questionnaireData.id}
                                                                value="Not applicable, skip this section"
                                                                status={questionnaireData.not_applicable}
                                                                onCheck={(e: any) => opemModalForNotApplicable(e, questionnaireData, sectionIndex)}
                                                                disabled={questionnaireData.completed}
                                                            />
                                                            {questionnaireFormData?.questionnaire_status !== 'Reviewed' ?
                                                                <Button
                                                                    variant="primary"
                                                                    onClick={() => saveAndContinue(questionnaireData, sectionIndex)}
                                                                    disabled={questionnaireData.not_applicable || questionnaireData.completed}
                                                                    className={questionnaireData.not_applicable ? "opacity-25" : ""}
                                                                >
                                                                    Save & Continue
                                                                </Button> : ""}
                                                        </Stack>
                                                    </div>
                                                </AccordionTab>
                                            )
                                        })}
                                    </Accordion> : ""}
                            </div>

                        </div>
                    </Card>
                    <div className="theme-from-outer-footer mt-auto pt-3">
                        <Stack
                            direction="horizontal"
                            gap={3}
                            className="justify-content-end"
                        >
                            {questionnaireFormData?.questionnaire_status !== 'Reviewed' ?
                                <Button
                                    variant="secondary"
                                    className="lh-1 py-3 text-white fs-5 min-w-160 d-none d-md-inline-block"
                                    onClick={submitHandler}
                                >
                                    Submit
                                </Button> : ""}
                        </Stack>
                    </div>
                </div>
            </div>

            {/* Validation Modal */}
            {validationModalShow ?
                <ValidationModal
                    show={validationModalShow}
                    isSection={isSectionSelected}
                    validationData={validationData}
                    handleClose={() => setValidationModalShow(false)}
                    proceedSection={(data: any) => proceedSection(data, currentSectionIndex)}
                    proceedPage={() => proceedPage()}
                    sectionData={sectionDataModal}
                /> : ""}

            {/* Delete Modal */}
            <DeleteModal
                show={showNotApplicableModal}
                handleClose={() => setShowNotApplicableModal(false)}
                deleteModalHeaderTitle={showNotapplicableData.title}
                deleteModalBodyContent={showNotapplicableData.content}
                customDeleteFunction={() => notApplicableCheckHandler(showNotapplicableData.checked, showNotapplicableData.section.section_id)}
            />

            < DeleteModal
                show={deleteShowValidation}
                handleClose={() => {
                    setDeleteShowValidation(false);
                }}
                customDeleteFunction={() => GoBack()}
                deleteModalHeaderTitle="Alert"
                deleteModalBodyContent={"Are you sure you want to leave, You have unsaved changes?"}
            />

            {/* Confirm Questionnaire Modal */}
            <ConfirmQuestionnaireModal
                show={confirmQuestionnaireModalShow}
                handleClose={() => {
                setConfirmQuestionnaireModalShow(false);
                }}
                modalConfirmButtonHandler={modalConfirmButtonHandler}
            />

        </>
    );
};

export default CrmQuestionnaireForm;

import { ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { Alert, Badge, Card, Col, Row, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ThemeCustomDropdown from '../../../components/common/CustomDropdown'
import Loader from '../../../components/common/Loader'
import { SvgIcons } from '../../../components/common/SvgIcons'
import { companyCaseActions, getCompanyCasesDetailsAction, getCompanyDropdownCasesAction } from '../../../redux/companyCasesModule/caseSlice'
import CaseTabs from './tabs'
import { useLocation } from 'react-router-dom'
import { MdLibraryBooks } from 'react-icons/md'

const CasesList = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlCaseID = Number(params.get('caseID'))
  const isLoading = useSelector((state: any) => state.companyCaseSlice.loading);
  const caseID = useSelector((state: any) => state.companyCaseSlice.caseID);
  const caseDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [caseOptions, setCaseOptions] = useState<any>(caseID ? caseID : '');
  const [caseOptionsData, setCaseOptionsData]: any = useState<any>([]);

  useEffect(() => {
    if (params.get('caseID')) {
      getCompanyCasesDropdownFromURL()
    } else {
      getCompanyCasesDropdown();
    }
  }, []);

  useEffect(() => {
    if(!caseOptions) return
    getCaseDetailsData()
  }, [caseOptions])

  const getCompanyCasesDropdown = () => {
    dispatch(getCompanyDropdownCasesAction()).then(data => {      
      if (data?.payload?.statusCode === 200) {
        let cases: any = []
        data?.payload?.data?.forEach((element: any) => {
          const name = [element?.case_client_info?.first_name, element?.case_client_info?.middle_name, element?.case_client_info?.last_name].filter(Boolean).join(" ")
          const customLabel = `${name} ${element?.case_type_info?.name} [${element?.file_number}]`
          cases.push({
            label: customLabel, value: element.id
          });
        });
        setCaseOptionsData(cases);
        setCaseOptions(caseID ? caseID : (cases[0]?.value ? cases[0]?.value : "" ));
        dispatch(companyCaseActions.changeCompanyCasesID({ caseID: caseID ? caseID : (cases[0]?.value ? cases[0]?.value : "" ) }));
      }
    })
  }

  const getCompanyCasesDropdownFromURL = () => {
    dispatch(getCompanyDropdownCasesAction()).then(data => {      
      if (data?.payload?.statusCode === 200) {
        let cases: any = []
        data?.payload?.data?.forEach((element: any) => {
          const name = [element?.case_client_info?.first_name, element?.case_client_info?.middle_name, element?.case_client_info?.last_name].filter(Boolean).join(" ")
          const customLabel = `${name} ${element?.case_type_info?.name} [${element?.file_number}]`
          cases.push({
            label: customLabel, value: element.id
          });
        });
        setCaseOptionsData(cases);
        setCaseOptions(urlCaseID ? urlCaseID : "");
        dispatch(companyCaseActions.changeCompanyCasesID({ caseID: urlCaseID ? urlCaseID : "" }));
      }
    })
  }

  const getCaseDetailsData = () => {
    dispatch(getCompanyCasesDetailsAction(caseOptions))
  }

  const handleCasesDropdown = (event: any) => {
    setCaseOptions(event.value);
    dispatch(companyCaseActions.changeCompanyCasesID({ caseID: event.value }));
  }

  const overdueClassName = (status: string) => {
    switch (status) {
      case "N/A - Current":
        return "bg-success-subtle text-success fw-bold fs-14";
      case "Does Not Apply":
        return "bg-dark-subtle text-dark fw-bold fs-14";
      default:
        return "bg-danger-subtle text-danger fw-bold fs-14";
    }
  };

  const billingTemplate = () => {
    const status = caseDetailsData?.billingDetail?.overByDays;

    const getLabel = (status: string) => {
      switch (status) {
        case "N/A - Current":
          return "Current";
        case "Does Not Apply":
          return "Does Not Apply";
        default:
          return "Overdue";
      }
    };

    return (
      <Badge
        pill
        className={overdueClassName(status)}
      >
        {getLabel(status)}
      </Badge>
    );
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="d-flex flex-column pageContainer p-0 h-100 overflow-auto new-theme-updates bg-body">
        <div className="contentHeader bg-body-tertiary p-3">
          <Stack
            direction="horizontal"
            gap={3}
            className="flex-wrap"
          >
            <h5 className="mb-0 position-relative fw-bold d-flex align-items-center ps-0 gap-3 text-custom-secondary me-auto">
              <div className="align-items-center bg-primary d-inline-flex justify-content-center rounded text-white theme-icon-box custom-width-44 custom-height-44 rounded-1">
                <span className="theme-icon-box-inner text-center">
                  <MdLibraryBooks size={28} />
                </span>
              </div>
              Case Details
            </h5>
            <div className="w-220">
              <ThemeCustomDropdown
                controlId="case_id"
                placeHolder="Case#"
                data={caseOptionsData}
                value={caseOptions}
                handleFilter={handleCasesDropdown}
              />
            </div>
          </Stack>
        </div>

        {/* <div className="contentHeader p-1">
          <Stack
            direction="horizontal"
            gap={2}
            className="flex-wrap mb-2"
          >
            <h1 className="fs-4 fw-semibold mb-0 me-auto">Cases</h1>
            <div className="w-220">
              <ThemeCustomDropdown
                controlId="case_id"
                placeHolder="Case#"
                data={caseOptionsData}
                value={caseOptions}
                handleFilter={handleCasesDropdown}
              />
            </div>
          </Stack>
        </div> */}

        <div className="flex-grow-1 pageContent position-relative p-3 d-flex flex-column">
          {/* <Card className="shadow-lg border-0 theme-card-cover"> */}
            {/* <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={3}
                className="flex-wrap px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-normal me-auto">
                  <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <span className="theme-icon-box-inner text-center">
                      {SvgIcons.caseManagementIcon}
                    </span>
                  </div>
                  Case Details
                </h5>
              </Stack>
            </div> */}

            {
              caseOptionsData?.length > 0 ?
                ""
                : <div className="pb-3">No Records Found</div>
            }

            {
              caseOptionsData?.length > 0 ?
                <div className="pt-1">
                  <Row className='justify-content-between'>
                    <Col sm className='mb-3'>
                      <h6 className="text-primary mb-0 fw-bold text-capitalize">
                        {[caseDetailsData?.case_client_info?.first_name,
                        caseDetailsData?.case_client_info?.middle_name,
                        caseDetailsData?.case_client_info?.last_name]
                          .filter(Boolean) // Removes falsy values like null, undefined, or empty strings
                          .join(' ') || 'N/A' // Joins the non-empty parts with a space, defaults to 'N/A' if all are blank
                        }
                      </h6>
                    </Col>
                    {
                      caseDetailsData?.signing_authority_info?.full_name ?
                        <Col sm={"auto"} className='mb-3'>
                          <h6 className="text-primary mb-0">
                            <span className='fw-bold text-nowrap'>Attorney: </span>
                            <span className="fw-normal text-capitalize">{caseDetailsData?.signing_authority_info?.full_name}</span>
                          </h6>
                        </Col>
                        : ""
                    }
                  </Row>
                  <div className='border-top pt-3 text-primary'>
                    <Row className='justify-content-between'>
                      <Col md={4} className='mb-3'>
                        <div className="d-flex">
                          <span className="text-nowrap fw-bold pe-1">Case Type: </span>
                          <span className="pe-2">{caseDetailsData?.case_type_info?.name}</span>
                          <span className='d-inline-flex my-auto'><Badge pill bg={caseDetailsData?.case_status !== 'Closed' ? 'custom-success-subtle text-success fs-14 fw-bold' : 'custom-danger-subtle text-danger fs-14 fw-bold'}>{caseDetailsData?.case_status}</Badge></span>
                        </div>
                      </Col>
                      <Col sm={6} md={4} className='mb-3'>
                        <div className="d-flex">
                          <span className="text-nowrap fw-bold pe-1">Case No: </span>
                          <span className="pe-1 me-auto">{caseDetailsData?.file_number}</span>
                        </div>
                      </Col>
                      <Col sm="auto" className='mb-3'>
                        <div className="align-items-center d-flex">
                          <span className="text-nowrap pe-2">Billing Status </span>
                          {billingTemplate()}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                : ""
            }
          {/* </Card> */}
          {
            caseOptionsData?.length > 0 ?
              <CaseTabs caseID={caseOptions} caseData={caseDetailsData} />
              : ""
          }
        </div>
      </div>
    </>
  )
}

export default CasesList